import React from 'react'
import {
  CFImage,
  CFView,
  CFText,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import { hero, heroText, mobileHero, mobileLogo } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${mobileHero}) bottom/ cover no-repeat`}
          column
          justifyStart
          alignCenter
        >
          <CFImage src={mobileLogo} w="75%" alt="Jin's Kitchen Map" />
          <CFView pulsate mv="20px" textCenter>
            <OrderPickupButton />
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="650px"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          zIndex={90}
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView row center bg="#CF5B55" ph="35px" pv="3px">
            <CFView bold white style={{ fontSize: 29 }}>
              DELICIOUS KOREAN FOOD!
            </CFView>
            <CFView ml="25px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
