import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  LocationSelect,
  MobileScreen,
} from 'components'
import { headerBg, logo, mobileLogo, logoTitle, viewMenu } from 'images'
import ViewMenuButton from './ViewMenuButton'
import { dispatch } from 'store'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'https://order.codefusion.tech/restaurants/8GFwb8UTNOQ2rbtqc9kk/locations/Y7GXtSFgqD6z5v9Jpd8q'

const images = []

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView column center w="100%" pt="10px" textCenter>
          <CFImage w="35%" src={mobileLogo} alt="Jin's Kitchen Logo" mb="3px" />
          {/* <ViewMenuButton /> */}
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center w="100%" zIndex={98} h="50px" bg="#CF5B55">
          <CFView row justifyBetween alignCenter w="100%" maxWidth="1400px">
            <CFImage
              ml="50px"
              mt="75px"
              h="120px"
              src={logo}
              alt="Jin's Kitchen Logo"
              zIndex={98}
            />
            {/* <CFView mt="10px">
              <ViewMenuButton />
            </CFView> */}
          </CFView>
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
